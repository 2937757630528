@mixin for-root {
  :root {
    --lf-linear-progress-bar-height: 12px;
    --lf-linear-progress-bar-border-radius: 3px;
    --lf-linear-progress-bar-gap: 2px;

    --lf-linear-progress-legend-spacing: 16px;
    --lf-linear-progress-legend-items-gap: 32px;
    --lf-linear-progress-legend-title-font: var(--lf-text-s-font);
    --lf-linear-progress-legend-value-font: var(--lf-subtitle-s-font);
    --lf-linear-progress-legend-item-gap: 4px;
    --lf-linear-progress-legend-value-gap: 6px;
  }

  .lf-linear-progress-bar {
    display: block;
  }

  .lf-linear-progress-bar__wrapper {
    display: flex;
    gap: var(--lf-linear-progress-bar-gap);

    .lf-progress-segment {
      display: block;
      height: var(--lf-linear-progress-bar-height);
      border-radius: var(--lf-linear-progress-bar-border-radius);
      flex-grow: 1;
      flex-shrink: 1;
      background-color: var(--lf-current-accent-400, var(--lf-main-900));
      transition-duration: var(--lf-transition-duration);
      transition-property: flex-basis;
    }
  }

  .lf-linear-progress-bar__legend {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--lf-linear-progress-legend-items-gap);
    row-gap: var(--lf-linear-progress-legend-spacing);
    margin-top: var(--lf-linear-progress-legend-spacing);
  }
  .lf-progress-legend-item__title {
    font: var(--lf-linear-progress-legend-title-font);
    color: var(--lf-text-300);
    margin-bottom: var(--lf-linear-progress-legend-item-gap);
  }
  .lf-progress-legend-item__value {
    display: flex;
    align-items: center;
    gap: var(--lf-linear-progress-legend-value-gap);
  }
  .lf-progress-legend-item__indicator {
    flex: 0 0 auto;
    width: var(--lf-linear-progress-bar-height);
    height: var(--lf-linear-progress-bar-height);
    border-radius: var(--lf-linear-progress-bar-border-radius);
    background-color: var(--lf-current-accent-400, var(--lf-main-900));
  }
  .lf-progress-legend-item__count {
    font: var(--lf-linear-progress-legend-value-font);
    color: var(--lf-text-900);
  }
}

