@use "theming/colors";

@mixin page-with-header-content {
  max-height: calc(100vh - var(--lf-page-header-height));
}

@mixin for-root {
  :root {
    --lf-main-menu-width: 64px;
    --lf-main-menu-item-height: 64px;
    --lf-main-submenu-width: 240px;
    --lf-main-menu-icon-size: 24px;
    --lf-main-submenu-secondary-icon-size: 20px;
    --lf-main-menu-icon-as-text-size: 17px;

    --lf-main-menu-badge-min-size: 16px;
    --lf-main-menu-badge-font: var(--lf-caption-s-font);
    --lf-main-menu-badge-padding: 2px 4px;

    --lf-page-header-height: 64px;

    --lf-page-gutter-size: 20px;
  }

  .lf-app {
    min-height: 100%;

    display: flex;
    position: relative;
  }
  .lf-app-menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: var(--lf-main-menu-width);
    height: 100%;
    background-color: var(--lf-bg-900);
  }
  .lf-app-main {
    width: calc(100% - var(--lf-main-menu-width));
    margin-left: var(--lf-main-menu-width);
  }

  .lf-page-with-header__content {
    @include page-with-header-content();
  }
}
