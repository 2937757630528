.app-blinker-loader {
  position: relative;
}
.app-blinker-loader_show {
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    cursor: wait;
    background-image: linear-gradient(to right, transparent 0%, var(--lf-bg-900) 15%, transparent 20%);
    background-color: var(--lf-bg-100);
    opacity: 0.1;
    background-size: 200% 100%;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: app-blinker-loader;
    animation-timing-function: linear;
  }
}
@keyframes app-blinker-loader {
  0%{
    background-position-x: 100%;
  }
  100%{
    background-position-x: -100%;
  }
}
