/* You can add global styles to this file, and also import other style files */
@use '@leafio/ui/styles' as lf;
@import '@leafio/ui/icons/assets.css';

@import "styles/helpers";
@import "styles/spacings";
@import "styles/tasks";
@import "styles/blinker-loader";
@import "styles/snow";

@include lf.all();
