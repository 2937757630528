.property-row {
  display: flex;
  gap: var(--lf-page-gutter-size);
  &:not(:last-child) {
    margin-bottom: var(--app-spacing-l);
  }
}
.property-row__cell {
  flex: 1 1 auto;
}
.property-row__cell_fixed {
  flex: 0 0 140px;
}
.property-icon {
  display: inline-flex;
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  background: var(--lf-current-accent-100, var(--lf-main-300));
  color: var(--lf-current-accent-500, var(--lf-text-500));
  border-radius: 50%;
  margin-right: 12px;
  font-size: 14px;
}
.app-task-table-indicator-icon {
  color: var(--lf-main-900);
}

.app-table {
  .lf-table-row:not(.lf-table-footer-row) {
    &:hover:not(.lf-table-row_active) {
      background-color: var(--lf-bg-300);
      .app-table__item-link {
        text-decoration: underline;
      }
    }
  }
}
