.app-filters-wrapper {
  padding: 12px var(--lf-page-gutter-size) 0;
}
.app-common-search-field {
  width: 300px;
}

.table-wrapper {
  margin-top: 12px;
}

.critical-error-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.app-hide-if-empty:empty {
  display: none !important;
}

.app-tooltip-icon {
  color: var(--lf-main-700);
}

.app-checklist-section + .app-checklist-section {
  margin-top: var(--app-spacing-m);
}
