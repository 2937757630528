@use './mixins';

@mixin styles (
  $name,
  $family: var(--lf-font-family),
  $size: 14px,
  $style: normal,
  $weight: 400,
  $height: 1.2,
) {
  --lf-#{$name}-font-family: #{$family};
  --lf-#{$name}-font-size: #{$size};
  --lf-#{$name}-font-style: #{$style};
  --lf-#{$name}-font-weight: #{$weight};
  --lf-#{$name}-line-height: #{$height};
  --lf-#{$name}-font: var(--lf-#{$name}-font-style) var(--lf-#{$name}-font-weight) var(--lf-#{$name}-font-size)/var(--lf-#{$name}-line-height) var(--lf-#{$name}-font-family);
}

@mixin for-root () {
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto:wght@400;500;700&display=swap');

  :root {
    --lf-font-family: 'Roboto', sans-serif;
    --lf-font-family-secondary: 'Montserrat', sans-serif;

    @include styles(
      $name: h1,
      $family: var(--lf-font-family-secondary),
      $size: 28px,
      $weight: 700,
      $height: 1.2,
    );

    @include styles(
      $name: h2,
      $family: var(--lf-font-family-secondary),
      $size: 24px,
      $weight: 700,
      $height: 1.15,
    );

    @include styles(
      $name: h3,
      $family: var(--lf-font-family),
      $size: 24px,
      $weight: 500,
      $height: 1.15,
    );

    @include styles(
      $name: h4,
      $family: var(--lf-font-family-secondary),
      $size: 22px,
      $weight: 700,
      $height: 1.15,
    );

    @include styles(
      $name: h5,
      $family: var(--lf-font-family-secondary),
      $size: 20px,
      $weight: 700,
      $height: 1.2,
    );

    @include styles(
      $name: h6,
      $family: var(--lf-font-family-secondary),
      $size: 18px,
      $weight: 700,
      $height: 1.2,
    );

    @include styles(
      $name: subtitle-l,
      $family: var(--lf-font-family),
      $size: 18px,
      $weight: 500,
      $height: 1.1,
    );

    @include styles(
      $name: subtitle,
      $family: var(--lf-font-family),
      $size: 16px,
      $weight: 500,
      $height: 1.1,
    );

    @include styles(
      $name: subtitle-m,
      $family: var(--lf-font-family),
      $size: 14px,
      $weight: 500,
      $height: 1.1,
    );

    @include styles(
      $name: subtitle-s,
      $family: var(--lf-font-family),
      $size: 12px,
      $weight: 500,
      $height: 1.15,
    );

    @include styles(
      $name: text-l,
      $family: var(--lf-font-family),
      $size: 16px,
      $height: 1.1,
    );

    @include styles(
      $name: text,
      $family: var(--lf-font-family),
      $size: 14px,
      $height: 1.1,
    );

    @include styles(
      $name: text-m,
      $family: var(--lf-font-family),
      $size: 13px,
      $height: 1.2,
    );

    @include styles(
      $name: text-s,
      $family: var(--lf-font-family),
      $size: 12px,
      $height: 1.1,
    );

    @include styles(
      $name: caption,
      $family: var(--lf-font-family),
      $size: 12px,
      $weight: 700,
      $height: 1.15,
    );

    @include styles(
      $name: caption-s,
      $family: var(--lf-font-family),
      $size: 10px,
      $weight: 700,
      $height: 1.2,
    );
  }

  body {
    font: var(--lf-text-font);
    color: var(--lf-text-900);
  }

  a {
    text-decoration: none;
    color: var(--lf-brand-500);
    &:hover {
      text-decoration: underline;
      color: var(--lf-brand-400);
    }
  }


  .lf-link {
    @include mixins.reset-button-styles();
    display: inline;
    text-decoration: none;
    color: var(--lf-brand-500);
    &:not([disabled]) {
      &:hover, &:focus-visible {
        text-decoration: underline;
        color: var(--lf-brand-400);
      }
    }
    &[disabled] {
      color: var(--lf-brand-200);
      cursor: not-allowed;
    }

    &.lf-link_with-icon {
      &:not([disabled]) {
        &:hover, &:focus-visible {
          text-decoration: none;

          .lf-link__text {
            text-decoration: underline;
          }
        }
      }
    }
  }


  h1, .h1 {
    font: var(--lf-h1-font);
  }
  h2, .h2 {
    font: var(--lf-h2-font);
  }
  h3, .h3 {
    font: var(--lf-h3-font);
  }
  h4, .h4 {
    font: var(--lf-h4-font);
  }
  h5, .h5 {
    font: var(--lf-h5-font);
  }
  h6, .h6 {
    font: var(--lf-h6-font);
  }

  .lf-subtitle-l {
    font: var(--lf-subtitle-l-font);
  }
  .lf-subtitle {
    font: var(--lf-subtitle-font);
  }
  .lf-subtitle-m {
    font: var(--lf-subtitle-m-font);
  }
  .lf-subtitle-s {
    font: var(--lf-subtitle-s-font);
  }

  .lf-text-l {
    font: var(--lf-text-l-font);
  }
  .lf-text {
    font: var(--lf-text-font);
  }
  .lf-text-m {
    font: var(--lf-text-m-font);
  }
  .lf-text-s {
    font: var(--lf-text-s-font);
  }

  .lf-caption {
    font: var(--lf-caption-font);
  }
  .lf-caption-s {
    font: var(--lf-caption-s-font);
  }

}
