@font-face {
    font-family: "LfIcons";
    src: url("./LfIcons.ttf?3a16f6ae1e8fb32d3bb09ddce56f5ae1") format("truetype"),
url("./LfIcons.woff?3a16f6ae1e8fb32d3bb09ddce56f5ae1") format("woff"),
url("./LfIcons.woff2?3a16f6ae1e8fb32d3bb09ddce56f5ae1") format("woff2");
}

.lf-icon:before {
    font-family: LfIcons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.lf-icon.lf-icon-arrow_counter_clockwise_bold:before {
    content: "\f101";
}
.lf-icon.lf-icon-arrow_down:before {
    content: "\f102";
}
.lf-icon.lf-icon-arrow_left:before {
    content: "\f103";
}
.lf-icon.lf-icon-arrow_right_double_curved:before {
    content: "\f104";
}
.lf-icon.lf-icon-arrow_right:before {
    content: "\f105";
}
.lf-icon.lf-icon-arrow_up_bold:before {
    content: "\f106";
}
.lf-icon.lf-icon-arrow_up_right_square_out:before {
    content: "\f107";
}
.lf-icon.lf-icon-arrows_counter_clockwise:before {
    content: "\f108";
}
.lf-icon.lf-icon-arrows_parallel_horizontal:before {
    content: "\f109";
}
.lf-icon.lf-icon-arrows_parallel_vertical:before {
    content: "\f10a";
}
.lf-icon.lf-icon-asterisk:before {
    content: "\f10b";
}
.lf-icon.lf-icon-attach:before {
    content: "\f10c";
}
.lf-icon.lf-icon-bell:before {
    content: "\f10d";
}
.lf-icon.lf-icon-block:before {
    content: "\f10e";
}
.lf-icon.lf-icon-bookmark_minus_filled:before {
    content: "\f10f";
}
.lf-icon.lf-icon-broom:before {
    content: "\f110";
}
.lf-icon.lf-icon-calendar:before {
    content: "\f111";
}
.lf-icon.lf-icon-camera:before {
    content: "\f112";
}
.lf-icon.lf-icon-car:before {
    content: "\f113";
}
.lf-icon.lf-icon-caret_down_small_filled:before {
    content: "\f114";
}
.lf-icon.lf-icon-caret_down_small:before {
    content: "\f115";
}
.lf-icon.lf-icon-caret_down:before {
    content: "\f116";
}
.lf-icon.lf-icon-caret_left_double:before {
    content: "\f117";
}
.lf-icon.lf-icon-caret_left:before {
    content: "\f118";
}
.lf-icon.lf-icon-caret_right_double:before {
    content: "\f119";
}
.lf-icon.lf-icon-caret_right:before {
    content: "\f11a";
}
.lf-icon.lf-icon-caret_up_small:before {
    content: "\f11b";
}
.lf-icon.lf-icon-caret_up:before {
    content: "\f11c";
}
.lf-icon.lf-icon-chart:before {
    content: "\f11d";
}
.lf-icon.lf-icon-check-circle:before {
    content: "\f11e";
}
.lf-icon.lf-icon-check_bold:before {
    content: "\f11f";
}
.lf-icon.lf-icon-check_circle_filled:before {
    content: "\f120";
}
.lf-icon.lf-icon-check:before {
    content: "\f121";
}
.lf-icon.lf-icon-checklist:before {
    content: "\f122";
}
.lf-icon.lf-icon-checks:before {
    content: "\f123";
}
.lf-icon.lf-icon-circle:before {
    content: "\f124";
}
.lf-icon.lf-icon-clock_countdown:before {
    content: "\f125";
}
.lf-icon.lf-icon-clock_timer:before {
    content: "\f126";
}
.lf-icon.lf-icon-clock:before {
    content: "\f127";
}
.lf-icon.lf-icon-clockwise:before {
    content: "\f128";
}
.lf-icon.lf-icon-close_bold:before {
    content: "\f129";
}
.lf-icon.lf-icon-close_circle_filled:before {
    content: "\f12a";
}
.lf-icon.lf-icon-close:before {
    content: "\f12b";
}
.lf-icon.lf-icon-cloud_upload:before {
    content: "\f12c";
}
.lf-icon.lf-icon-columns_2:before {
    content: "\f12d";
}
.lf-icon.lf-icon-comment_dialog:before {
    content: "\f12e";
}
.lf-icon.lf-icon-comment_exclamation:before {
    content: "\f12f";
}
.lf-icon.lf-icon-comment_lock:before {
    content: "\f130";
}
.lf-icon.lf-icon-comment:before {
    content: "\f131";
}
.lf-icon.lf-icon-copy:before {
    content: "\f132";
}
.lf-icon.lf-icon-delete:before {
    content: "\f133";
}
.lf-icon.lf-icon-desktop:before {
    content: "\f134";
}
.lf-icon.lf-icon-discount:before {
    content: "\f135";
}
.lf-icon.lf-icon-document_open:before {
    content: "\f136";
}
.lf-icon.lf-icon-download:before {
    content: "\f137";
}
.lf-icon.lf-icon-drag_indicator:before {
    content: "\f138";
}
.lf-icon.lf-icon-edit_graph:before {
    content: "\f139";
}
.lf-icon.lf-icon-edit:before {
    content: "\f13a";
}
.lf-icon.lf-icon-email_send:before {
    content: "\f13b";
}
.lf-icon.lf-icon-exclamation_filled:before {
    content: "\f13c";
}
.lf-icon.lf-icon-exclamation:before {
    content: "\f13d";
}
.lf-icon.lf-icon-face_sad:before {
    content: "\f13e";
}
.lf-icon.lf-icon-file_check:before {
    content: "\f13f";
}
.lf-icon.lf-icon-file_error:before {
    content: "\f140";
}
.lf-icon.lf-icon-file_plus:before {
    content: "\f141";
}
.lf-icon.lf-icon-file:before {
    content: "\f142";
}
.lf-icon.lf-icon-filter_dashed:before {
    content: "\f143";
}
.lf-icon.lf-icon-filter:before {
    content: "\f144";
}
.lf-icon.lf-icon-flag_filled:before {
    content: "\f145";
}
.lf-icon.lf-icon-flag:before {
    content: "\f146";
}
.lf-icon.lf-icon-folder_simple_plus:before {
    content: "\f147";
}
.lf-icon.lf-icon-folder_star:before {
    content: "\f148";
}
.lf-icon.lf-icon-globe:before {
    content: "\f149";
}
.lf-icon.lf-icon-info_bold:before {
    content: "\f14a";
}
.lf-icon.lf-icon-info_circle:before {
    content: "\f14b";
}
.lf-icon.lf-icon-items_card:before {
    content: "\f14c";
}
.lf-icon.lf-icon-link_add:before {
    content: "\f14d";
}
.lf-icon.lf-icon-link_break:before {
    content: "\f14e";
}
.lf-icon.lf-icon-link:before {
    content: "\f14f";
}
.lf-icon.lf-icon-lock_arrow_clockwise:before {
    content: "\f150";
}
.lf-icon.lf-icon-lock:before {
    content: "\f151";
}
.lf-icon.lf-icon-log_out:before {
    content: "\f152";
}
.lf-icon.lf-icon-math_equal:before {
    content: "\f153";
}
.lf-icon.lf-icon-math_greater_equal:before {
    content: "\f154";
}
.lf-icon.lf-icon-math_greater:before {
    content: "\f155";
}
.lf-icon.lf-icon-math_less:before {
    content: "\f156";
}
.lf-icon.lf-icon-math_lesss_equal:before {
    content: "\f157";
}
.lf-icon.lf-icon-minus_bold:before {
    content: "\f158";
}
.lf-icon.lf-icon-minus_circle_filled:before {
    content: "\f159";
}
.lf-icon.lf-icon-minus_circle:before {
    content: "\f15a";
}
.lf-icon.lf-icon-minus:before {
    content: "\f15b";
}
.lf-icon.lf-icon-mobile:before {
    content: "\f15c";
}
.lf-icon.lf-icon-more_vertical:before {
    content: "\f15d";
}
.lf-icon.lf-icon-password_reset:before {
    content: "\f15e";
}
.lf-icon.lf-icon-plus_bold:before {
    content: "\f15f";
}
.lf-icon.lf-icon-plus_circle_filled:before {
    content: "\f160";
}
.lf-icon.lf-icon-plus:before {
    content: "\f161";
}
.lf-icon.lf-icon-question_circle:before {
    content: "\f162";
}
.lf-icon.lf-icon-repeat:before {
    content: "\f163";
}
.lf-icon.lf-icon-replace:before {
    content: "\f164";
}
.lf-icon.lf-icon-scanner_barcode:before {
    content: "\f165";
}
.lf-icon.lf-icon-search:before {
    content: "\f166";
}
.lf-icon.lf-icon-send:before {
    content: "\f167";
}
.lf-icon.lf-icon-settings_simple:before {
    content: "\f168";
}
.lf-icon.lf-icon-settings:before {
    content: "\f169";
}
.lf-icon.lf-icon-shield_question:before {
    content: "\f16a";
}
.lf-icon.lf-icon-siren:before {
    content: "\f16b";
}
.lf-icon.lf-icon-snowflake_crossed:before {
    content: "\f16c";
}
.lf-icon.lf-icon-snowflake:before {
    content: "\f16d";
}
.lf-icon.lf-icon-store:before {
    content: "\f16e";
}
.lf-icon.lf-icon-subitems:before {
    content: "\f16f";
}
.lf-icon.lf-icon-support:before {
    content: "\f170";
}
.lf-icon.lf-icon-task_square:before {
    content: "\f171";
}
.lf-icon.lf-icon-text_direction_top_right:before {
    content: "\f172";
}
.lf-icon.lf-icon-unlock_check:before {
    content: "\f173";
}
.lf-icon.lf-icon-unlock:before {
    content: "\f174";
}
.lf-icon.lf-icon-user_add:before {
    content: "\f175";
}
.lf-icon.lf-icon-user:before {
    content: "\f176";
}
.lf-icon.lf-icon-users:before {
    content: "\f177";
}
.lf-icon.lf-icon-visible_off:before {
    content: "\f178";
}
.lf-icon.lf-icon-visible:before {
    content: "\f179";
}
.lf-icon.lf-icon-warning_circle_filled:before {
    content: "\f17a";
}
.lf-icon.lf-icon-warning_circle:before {
    content: "\f17b";
}
.lf-icon.lf-icon-warning_triangle_filled:before {
    content: "\f17c";
}
