@mixin for-root {
  :root {
    --lf-alert-padding-vertical: 10px;
    --lf-alert-padding-horizontal: 10px;
    --lf-alert-min-height: 40px;
    --lf-alert-gap: 8px;
    --lf-alert-border-radius: 8px;

    --lf-alert-icon-size: 18px;
    --lf-alert-content-font: var(--lf-text-font);
  }

  .lf-alert {
    display: flex;
    gap: var(--lf-alert-gap);
    align-items: center;
    padding: var(--lf-alert-padding-vertical) var(--lf-alert-padding-horizontal);
    min-height: var(--lf-alert-min-height);

    font: var(--lf-alert-content-font);
    color: var(--lf-text-500);
    border-radius: var(--lf-alert-border-radius);
    background: var(--lf-current-accent-100, var(--lf-bg-200));

    a {
      color: var(--lf-current-accent-500, var(--lf-main-900));
      &:hover, &:focus-visible {
        color: var(--lf-current-accent-400, var(--lf-main-700));
      }
    }
  }

  .lf-alert__icon {
    font-size: var(--lf-alert-icon-size);
    width: 1em;
    height: 1em;
    color: var(--lf-current-accent-500, var(--lf-main-900));
  }
}
