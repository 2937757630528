@use '../mixins' as lf;

@mixin for-root {
  :root {
    --lf-list-border-radius: 6px;
    --lf-list-border-width: 1px;

    --lf-list-item-height: 52px;
    --lf-list-item-padding-horizontal: 16px;
    --lf-list-item-padding-vertical: 8px;
    --lf-list-item-gap: 12px;
    --lf-list-item-border-width: 1px;
    --lf-list-item-text-lines-gap: 4px;
    --lf-list-item-font: var(--lf-subtitle-m-font);
    --lf-list-item-font-secondary: var(--lf-text-m-font);

    --lf-list-item-icon-size: 20px;

    --lf-list-sticky-border-width: 1px;
    --lf-list-sticky-padding-horizontal: var(--lf-list-item-padding-horizontal);
    --lf-list-sticky-padding-vertical: 5px;
    --lf-list-sticky-font: var(--lf-subtitle-s-font);

    --lf-actions-list-min-width: 175px;
    --lf-actions-list-item-min-height: 43px;
    --lf-actions-list-item-padding-vertical: 6px;
    --lf-actions-list-item-padding-horizontal: 12px;
    --lf-actions-list-item-gap: 14px;
    --lf-actions-list-item-icon-size: 18px;
    --lf-actions-list-item-border-width: 1px;
    --lf-actions-list-item-separator-width: 2px;
    --lf-actions-list-item-font: var(--lf-text);
  }

  .lf-actions-list {
    display: block;
    // min-width for inline/dropdown layouts
    min-width: var(--lf-actions-list-min-width);
  }
  button.lf-actions-list-item {
    @include lf.reset-button-styles();
  }
  a.lf-actions-list-item {
    text-decoration: none;
  }
  .lf-actions-list-item,
  button.lf-actions-list-item,
  a.lf-actions-list-item {
    display: block;
    width: 100%;
    cursor: pointer;
    border-top: var(--lf-actions-list-item-border-width) solid var(--lf-main-300);
    transition-duration: var(--lf-transition-duration);
    transition-property: background-color;
    background-color: var(--lf-bg-100);
    text-align: left;
    &:hover, &:focus-visible {
      background-color: var(--lf-current-accent-100, var(--lf-bg-300));
    }
    &:first-child {
      border-top: none;
    }
    &.lf-actions-list-item__separated {
      border-top-width: var(--lf-actions-list-item-separator-width);
    }
    &.lf-actions-list-item__disabled, &[disabled] {
      cursor: not-allowed;
      background-color: var(--lf-bg-100);

      .lf-actions-list-item__content,
      .lf-actions-list-item__icon .lf-icon {
        color: var(--lf-current-accent-200, var(--lf-main-500));
      }
    }
  }

  .lf-actions-list-item__wrapper {
    display: flex;
    align-items: center;
    min-height: var(--lf-actions-list-item-min-height);
    padding: var(--lf-actions-list-item-padding-vertical) var(--lf-actions-list-item-padding-horizontal);
    gap: var(--lf-actions-list-item-gap);

  }
  .lf-actions-list-item__icon {
    display: inline-flex;
    flex: 0 0 auto;
    &:empty {
      display: none;
    }
    .lf-icon {
      font-size: var(--lf-actions-list-item-icon-size);
      color: var(--lf-current-accent-500, var(--lf-main-900));
      transition-duration: var(--lf-transition-duration);
      transition-property: color;
    }
  }
  .lf-actions-list-item__icon_suffix {
    .lf-icon {
      color: var(--lf-current-accent-500, var(--lf-main-700));
    }
  }
  .lf-actions-list-item__content {
    flex: 1 1 auto;
    font: var(--lf-actions-list-item-font);
    line-height: 1;
    color: var(--lf-current-accent-500, var(--lf-text-900));
    transition-duration: var(--lf-transition-duration);
    transition-property: color;
  }
}
