:root {
  --app-spacing-xs: 8px;
  --app-spacing-s: 12px;
  --app-spacing-m: 16px;
  --app-spacing-l: 24px;
}

.app-mb-l {
  margin-bottom: var(--app-spacing-l);
}
.app-mb-m {
  margin-bottom: var(--app-spacing-m);
}
.app-mb-s {
  margin-bottom: var(--app-spacing-s);
}
.app-mb-xs {
  margin-bottom: var(--app-spacing-xs);
}

.app-mt-l {
  margin-top: var(--app-spacing-l);
}
.app-mt-m {
  margin-top: var(--app-spacing-m);
}
.app-mt-s {
  margin-top: var(--app-spacing-s);
}
.app-mt-xs {
  margin-top: var(--app-spacing-xs);
}

.app-gap-l {
  gap: var(--app-spacing-l);
}
.app-gap-m {
  gap: var(--app-spacing-m);
}
.app-gap-s {
  gap: var(--app-spacing-s);
}
.app-gap-xs {
  gap: var(--app-spacing-xs);
}
