@mixin for-root () {
  :root {
    --lf-card-padding-vertical: 24px;
    --lf-card-padding-horizontal: 20px;
    --lf-card-border-width: 1px;
    --lf-card-border-radius: 10px;

    --lf-card-header-margin-bottom: 24px;
    --lf-card-header-margin: 0 0 var(--lf-card-header-margin-bottom);
    --lf-card-header-color: var(--lf-brand-500);
  }

  .lf-card, .lf-card-group {
    border-radius: var(--lf-card-border-radius);
    border: var(--lf-card-border-width) solid var(--lf-main-300);
    background-color: var(--lf-bg);
  }
  .lf-card {
    padding: var(--lf-card-padding-vertical) var(--lf-card-padding-horizontal);
  }
  .lf-card_no-paddings {
    padding: 0;
  }

  .lf-card-header {
    margin: var(--lf-card-header-margin);
    color: var(--lf-card-header-color);
  }

  .lf-card-hr {
    margin-left: calc(-1 * var(--lf-card-padding-horizontal));
    margin-right: calc(-1 * var(--lf-card-padding-horizontal));
  }

  .lf-card-group > .lf-card {
    --lf-card-in-group-border-radius: calc(var(--lf-card-border-radius) - var(--lf-card-border-width));

    border-radius: 0;
    border-width: 0 0 var(--lf-card-border-width) 0;

    &:first-child {
      border-top-left-radius: var(--lf-card-in-group-border-radius);
      border-top-right-radius: var(--lf-card-in-group-border-radius);
    }
    &:last-child {
      border-bottom-left-radius: var(--lf-card-in-group-border-radius);
      border-bottom-right-radius: var(--lf-card-in-group-border-radius);
      border-bottom-width: 0;
    }
  }
}
